import Vue from 'vue';
import Component from 'vue-class-component';
import CardProjectComponent from '@/components/card-project/card-project.component';
import { ApiServices } from '@/services/api.services';
import { Project } from '@/class/project';
import { DUNATI_DOMAIN, ROBOT_CONTENT } from '@/main';
import DunatiLoaderSimpleComponent from '@/components/dunati-loader-simple/dunati-loader-simple.component';
const _ = require('lodash');

@Component({
  components: {
    CardProjectComponent,
    DunatiLoaderSimpleComponent
  },
})
export default class ProjectsCategoriesPage extends Vue {

  public loading: boolean = false;
  public categories: any = [];
  public listProjects: Project[] = [];
  public tokeepProjects: Project[] = []
  public listPlaceholders = Array(3).fill(1);
  public current: any = null;

  public itemsPeerPage = 6; // cantidad de proyectos a mostrar
  public itemsPeerPageKeep = 6; // para resetear al anterior

  constructor() {
    super();
    this.$options.dunatiHeadTags = {
      title: 'Proyectos de oficinas en Chile | Dunati',
      metaTags: [
        /* Primaty Tags */
        {
          name: 'description',
          content: 'Ejemplos reales de oficinas realizados con mobiliario Dunati. Visualiza todos los espacios de oficina: mesas de reuniones, estaciones de trabajo, escritorios ejecutivos, sillas de oficinas.',
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        /* Open Graph / Facebook */
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}/proyectos`,
        },
        {
          property: 'og:title',
          content: 'Proyectos de oficinas en Chile | Dunati',
        },
        {
          property: 'og:description',
          content: 'Ejemplos reales de oficinas realizados con mobiliario Dunati. Visualiza todos los espacios de oficina: mesas de reuniones, estaciones de trabajo, escritorios ejecutivos, sillas de oficinas.',
        },
        {
          property: 'og:image',
          content:  `${DUNATI_DOMAIN}/assets/images/home_page/og_home.jpg`,
        },
        /* Twitter */
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}/proyectos`,
        },
      ],
    };
  }

  public mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.getInitData();
  }

  public destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  public handleScroll(event: Event) {
    if (typeof window !== 'undefined' && !this.loading) {
      const div = document.querySelector('.cnt-list-project');
      const st = window.scrollY || document.documentElement.scrollTop;

      if(div && st >= (div.scrollHeight - (div.scrollHeight * 0.20))) {
        this.loadMore();
      }
    }
  }

  public getInitData() {
    this.loading = true;
    ApiServices.getProjectCategories().then((cResponse) => {
      if (cResponse.data) {
        this.categories = cResponse.data.data.map((x: any) => x.name.toLowerCase());
      }

      ApiServices.getProjects().then((pResponse) => {
        if (pResponse.data) {
          const projects = pResponse.data.data;
          this.listProjects = projects.map((x: any) => new Project(x));

          this.listProjects = this.listProjects.sort((a: Project, b: Project) => b.rate - a.rate);
          this.tokeepProjects = this.listProjects;
        }

        this.loading = false;
        this.listPlaceholders = [];
      });
    });
  }

  public showCategory(category: string) {
    if (category && category !== 'otros') {
      this.current = category;
      const check = (obj: any) => obj.name.toLowerCase() === category;
      this.listProjects = this.tokeepProjects.filter((x: any) => x.categories.some(check));
    } else if (category === 'otros') {
      this.current = category;
      const list = this.tokeepProjects;

      for (let i = 0; i < this.categories.length; i++) {
        const element = this.categories[i];
        if (element !== 'otros') {
          const check = (obj: any) => obj.name.toLowerCase() === element;
          this.listProjects = this.tokeepProjects.filter((x: any) => x.categories.some(check));
        }
      }

      this.listProjects = list;
    } else {
      this.current = null;
      this.listProjects = this.tokeepProjects;
    }
  }

  public loadMore() {
    if(this.itemsPeerPage > this.listProjects.length) return;

    let oldPosition: number = window.scrollY;
    this.itemsPeerPage += this.itemsPeerPageKeep;

    this.$nextTick(() => {
      window.scrollTo(0, oldPosition);
    });
  }
}

