import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DunatiLoaderComponent from '@/components/dunati-loader/dunati-loader.component';
import { ApiServices } from '@/services/api.services';

const components: any = {
  DunatiLoaderComponent,
};

@Component({
  components,
})
export default class CardProjectComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public data: Project;
  public hover = false;

  public mounted() {}

  public details(slug: string) {
    this.$router.push({ name: 'project-details', params: { slug } });
  }

  public getAlt(): string {
    if (this.data.img && this.data.img.alternativeText !== "") {
      return this.data.img.alternativeText;
    } else if(this.data.img && this.data.img.name) {
      const cover = (this.data.img.name + '').split('/');
      const img = cover[cover.length - 1].split('.')[0];
      return `${img.replace(/-/g, ' ')}`;
    } else {
      return '';
    }
  }

  public getTitle(): string {
    if (this.data.img && this.data.img.caption !== "") {
      return this.data.img.caption;
    } else if(this.data.img && this.data.img.name) {
      const cover = (this.data.img.name + '').split('/');
      const img = cover[cover.length - 1].split('.')[0];
      return `${img.replace(/-/g, ' ')}`;
    } else {
      return '';
    }
  }

  public getImage() {
    if (this.data.img) {
      return ApiServices.strapiUrl + this.data.img.url;
    } else {
      return '';
    }
  }
}

